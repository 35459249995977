<template>
  <div class="mt-5 pt-2">
    <div class="card" :class="$style.container">
      <div class="text-dark font-size-32 mb-3">Sign In</div>
      <div class="mb-4">
        Please login to access Power Media application suite.
      </div>
      <a-form
        :model="loginForm"
        :rules="rules"
        layout="vertical"
        class="mb-4"
        @finish="handleFinish"
        @finishFailed="handleFinishFailed"
      >
        <a-form-item name="email">
          <a-input v-model:value="loginForm.email" placeholder="Email" />
        </a-form-item>
        <a-form-item name="password">
          <a-input v-model:value="loginForm.password" placeholder="Password" type="password" />
        </a-form-item>
        <a-button type="primary" html-type="submit" class="text-center w-100" :loading="loading">
          <strong>Sign in</strong>
        </a-button>
      </a-form>
      <router-link to="/auth/forgot-password" class="vb__utils__link">
        Forgot Password?
      </router-link>
    </div>
    <div class="text-center pt-2 mb-auto">
      <span class="mr-2">Don't have an account?</span>
      <router-link to="/auth/register" class="vb__utils__link">
        Sign up
      </router-link>
    </div>
  </div>
</template>
<script>
import { computed, reactive } from 'vue'
import { useStore } from 'vuex'
import * as actions from '../../../store/actions.type'

export default {
  name: 'VbLogin',
  setup() {
    const store = useStore()
    const settings = computed(() => store.getters.settings)
    const loading = computed(() => store.getters['user/user'].loading)
    const rules = {
      email: [
        {
          required: true,
          message: 'Please input your email!',
          trigger: 'change',
        },
      ],
      password: [{ required: true, message: 'Please input password!', trigger: 'change' }],
    }
    const loginForm = reactive({
      email: '',
      password: '',
    })

    const handleFinish = (values) => {
      store.dispatch(`user/${actions.UsersAction.LOGIN_USER_ACTION}`, { payload: values })
    }
    const handleFinishFailed = (errors) => {
      console.log(errors)
    }

    return {
      settings,
      loading,
      rules,
      loginForm,
      handleFinish,
      handleFinishFailed,
    }
  },
}
</script>
<style lang="scss" module>
@import '@/components/Auth/style.module.scss';
</style>
